import React from 'react';
import Separator from './Separator';

const Copyright = () => {
  return (
    <div style={{ marginTop: '1em', marginBottom: '1em', textAlign: 'center' }}>
      <Separator />
      <span style={{ display: 'inline-block', marginTop: 6 }}>© FullCall.com Wszelkie prawa zastrzeżone</span>
    </div>
  );
};

export default Copyright;
