import React, { useCallback, useState } from 'react';
import './TextField.css';

const TextField = ({
  label = 'label',
  defaultValue = '',
  fieldName = 'field',
  type = 'text',
  placeholder = '',
  onChange
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  }, [setValue, onChange]);

  return (
    <div className='form_block TextField'>
      <div className='controls'>
        <div className='controls__label'>
          <label
            className='label label_required'
            htmlFor={fieldName}
          >
            {label}
          </label>
        </div>

        <div className='controls__content'>
          <div className='controls__element'>
            <input
              className='control'
              type={type}
              value={value}
              onChange={handleChange}
              name={fieldName}
              id={fieldName}
              placeholder={placeholder}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextField;
