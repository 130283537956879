import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Button, { ButtonSize } from 'components/common/Button';

const LoginHeader = () => {
  const location = document.referrer.replace(/http(s):\/\//, '').replace(/\/$/, '');

  const history = useHistory();
  const handleRegister = useCallback(() => {
    history.replace('/register');
  }, [history]);

  return (
    <header className='ui__section-header'>

      <div className='ui__section-title-actions'>
        <div className='col_grow'>
          <h2 className='h3'>
            Logowanie FullCall do {location}
          </h2>
        </div>

        <aside className='col_auto'>
          <div className='row row_vcenter'>
            <div className='col_auto hidden-xs-down'>
              <div className='buttons'>
                <Button
                  onClick={handleRegister}
                  size={ButtonSize.s}
                >
                  Stwórz konto
                </Button>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </header>
  );
};

export default LoginHeader;
