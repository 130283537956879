import React, { useCallback, useMemo, useState } from 'react';
import Button, { ButtonVariant } from 'components/common/Button';
import RegisterHeader from './RegisterHeader';
import FcLogo from 'assets/sygnet_white.svg';
import FcLogoColor from 'assets/sygnet_color.svg';
import { FC_HTTP_ROOT } from 'config';
import TextField from 'components/common/TextField';
import MessageBox, { MessageBoxVariant } from 'components/common/MessageBox';
import Checkbox from 'components/common/Checkbox';
import './Register.css';
import { Link, useHistory } from 'react-router-dom';

const RegisterError = {
  EMPTY_FIELDS: 'EMPTY_FIELDS',
  TOO_SIMPLE_PASSWORD: 'TOO_SIMPLE_PASSWORD',
  PHONE_NUMBER_INVALID: 'PHONE_NUMBER_INVALID',
  TOS_NOT_ACCEPTED: 'TOS_NOT_ACCEPTED',
  NO_STORE_ADDRESS: 'NO_STORE_ADDRESS',
  // server errors:
  MAIL_IN_USE: 'MAIL_IN_USE',
  INCORRECT_SHOPER_DATA: 'INCORRECT_SHOPER_DATA',
  SITE_ALREADY_EXISTS: 'SITE_ALREADY_EXISTS',
  SERVER_ERROR: 'SERVER_ERROR'
};

const useShoperParams = (windowLocation) => {
  const search = useMemo(() => new URLSearchParams(windowLocation.search), [windowLocation.search]);
  const storeAddress = document.referrer.replace(/http(s):\/\//, '').replace(/\/$/, '');
  const storeIdentifier = storeAddress.split('.')[0];
  const params = useMemo(() => [
    `${storeIdentifier.substring(0, 1).toUpperCase()}${storeIdentifier.substring(1)}`,
    storeAddress,
    search.get('place'),
    search.get('shop'),
    search.get('timestamp'),
    search.get('hash')
  ], [search, storeAddress, storeIdentifier]);
  return params;
};

const Register = () => {
  const history = useHistory();

  const [
    userName,
    storeAddress,
    place,
    shop,
    timestamp,
    hash
  ] = useShoperParams(window.location);

  const [tosAccepted, setTosAccepted] = useState(false);
  const [inProgress, setProgress] = useState(false);
  const [error, setError] = useState(
    (!hash || !place || !shop || !timestamp)
      ? RegisterError.NO_STORE_ADDRESS
      : false
  );

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    setError(false);

    if (!storeAddress) {
      setError(RegisterError.NO_STORE_ADDRESS);
      return;
    }

    if (!tosAccepted) {
      setError(RegisterError.TOS_NOT_ACCEPTED);
      return;
    }

    const [
      storeName,
      email,
      password,
      phoneNumber
    ] = [...event.target.querySelectorAll('input')].map(({ value }) => value);

    if ([storeName, email, password, phoneNumber].some((value) => !value)) {
      setError(RegisterError.EMPTY_FIELDS);
      return;
    }

    if (password.length < 8) {
      setError(RegisterError.TOO_SIMPLE_PASSWORD);
      return;
    }

    if (!/^[0-9]{7,22}$/.test(phoneNumber)) {
      setError(RegisterError.PHONE_NUMBER_INVALID);
      return;
    }

    setProgress(true);
    const registerResult = await window.fetch(`${FC_HTTP_ROOT}/api/auth/register/shoper`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        shoper: { place, timestamp, shop, hash },
        store: { storeName, storeAddress, email, password, phoneNumber }
      })
    });
    setProgress(false);

    const response = await registerResult.json();
    if (registerResult.status >= 400) {
      const errorType = response.message || 'SERVER_ERROR';
      setError(errorType);
      return;
    }

    const { token } = response;
    window.localStorage.setItem('fcToken', token);
    history.replace('/open-panel');
  }, [
    place,
    timestamp,
    shop,
    hash,
    tosAccepted,
    storeAddress,
    history,
    setError
  ]);

  const handleTosChange = useCallback((checked) => {
    setTosAccepted(checked);
    setError(false);
  }, [
    setTosAccepted,
    setError
  ]);

  return (
    <div className='row'>
      <div className='col_xs-12'>
        <RegisterHeader />

        <div className='ui__section-main'>
          <section className='col_xs-12 ui__section-content'>
            <div className='ui__section-content-box RegisterContent'>

              <form onSubmit={handleSubmit}>
                <span className='ui__form-legend ui__form-legend_sticky'>
                  Wprowadź dane dla konta
                  <img src={FcLogoColor} alt='FullCall logo' height={26} style={{ marginLeft: 4 }} /> FullCall
                </span>

                {
                  error === RegisterError.NO_STORE_ADDRESS && (
                    <div className='row row_hcenter'>
                      <div className='col_auto'>
                        <MessageBox
                          title='Błąd'
                          content={<>Ta aplikacja jest przeznaczona dla platformy Shoper.<br /><a href={`${FC_HTTP_ROOT}/register`}>Załóż konto tutaj</a></>}
                          variant={MessageBoxVariant.alert}
                        />
                      </div>
                    </div>
                  )
                }

                {
                  error === RegisterError.INCORRECT_SHOPER_DATA && (
                    <div className='row row_hcenter'>
                      <div className='col_auto'>
                        <MessageBox
                          title='Nieudana weryfikacja platformy'
                          content='Skontaktuj się z dostawcą usługi Shoper.'
                          variant={MessageBoxVariant.alert}
                        />
                      </div>
                    </div>
                  )
                }

                {
                  error === RegisterError.SERVER_ERROR && (
                    <div className='row row_hcenter'>
                      <div className='col_auto'>
                        <MessageBox
                          title='Błąd serwera'
                          content={<>Skontaktuj się z nami na <a href='https://fullcall.com'>fullcall.com</a>.</>}
                          variant={MessageBoxVariant.alert}
                        />
                      </div>
                    </div>
                  )
                }

                {
                  error === RegisterError.EMPTY_FIELDS && (
                    <div className='row row_hcenter'>
                      <div className='col_auto'>
                        <MessageBox
                          title='Wprowadź dane'
                          content='Wszystkie pola są wymagane do założenia konta.'
                          variant={MessageBoxVariant.alert}
                        />
                      </div>
                    </div>
                  )
                }

                {
                  error === RegisterError.SITE_ALREADY_EXISTS && (
                    <div className='row row_hcenter'>
                      <div className='col_auto'>
                        <MessageBox
                          title='Błąd'
                          content={<>Konto FullCall dla tej strony już istnieje.<br /><Link to='/login'>Zaloguj się</Link></>}
                          variant={MessageBoxVariant.alert}
                        />
                      </div>
                    </div>
                  )
                }

                <div className='row row_hcenter'>
                  <div className='col_auto'>
                    <TextField
                      label='Nazwa sklepu'
                      fieldName='text'
                      type='text'
                      defaultValue={userName}
                      placeholder='Butik'
                    />
                  </div>
                </div>

                {
                  error === RegisterError.MAIL_IN_USE && (
                    <div className='row row_hcenter RegisterMessageBox'>
                      <div className='col_auto'>
                        <MessageBox
                          title='Podaj unikatowy adres email'
                          content='Podany adres email jest już wykorzystany.'
                          variant={MessageBoxVariant.alert}
                        />
                      </div>
                    </div>
                  )
                }

                <div className='row row_hcenter'>
                  <div className='col_auto'>
                    <TextField
                      label='Email'
                      fieldName='email'
                      type='email'
                      placeholder='email@example.com'
                    />
                  </div>
                </div>

                {
                  error === RegisterError.TOO_SIMPLE_PASSWORD && (
                    <div className='row row_hcenter RegisterMessageBox'>
                      <div className='col_auto'>
                        <MessageBox
                          title='Wprowadź bezpieczne hasło'
                          content='Hasło musi mieć co najmniej 8 znaków.'
                          variant={MessageBoxVariant.alert}
                        />
                      </div>
                    </div>
                  )
                }

                <div className='row row_hcenter'>
                  <div className='col_auto'>
                    <TextField
                      label='Hasło'
                      fieldName='password'
                      type='password'
                      placeholder=''
                    />
                  </div>
                </div>

                {
                  error === RegisterError.PHONE_NUMBER_INVALID && (
                    <div className='row row_hcenter RegisterMessageBox'>
                      <div className='col_auto'>
                        <MessageBox
                          title='Wprowadź poprawny numer telefonu'
                          content='Numer zostanie wykorzystany jako opcja kontaktu z Twoim sklepem.'
                          variant={MessageBoxVariant.alert}
                        />
                      </div>
                    </div>
                  )
                }

                <div className='row row_hcenter'>
                  <div className='col_auto'>
                    <TextField
                      label='Numer telefonu'
                      fieldName='tel'
                      type='tel'
                      placeholder='Numer telefonu kontaktowego'
                    />
                  </div>
                </div>

                {
                  error === RegisterError.TOS_NOT_ACCEPTED && (
                    <div className='row row_hcenter RegisterMessageBox'>
                      <div className='col_auto'>
                        <MessageBox
                          title='Zaakceptuj regulamin'
                          content='Akceptacja regulaminu jest wymagana do założenia konta.'
                          variant={MessageBoxVariant.alert}
                        />
                      </div>
                    </div>
                  )
                }

                <div className='row row_hcenter RegisterTos'>
                  <div className='col_auto'>
                    <Checkbox
                      label={<>Akceptuję postanowienia <a target='_blank' rel='noreferrer' href='https://fullcall.com/regulamin/'>regulaminu</a> </>}
                      checked={tosAccepted}
                      onChange={handleTosChange}
                    />
                  </div>
                </div>

                <div className='row row_hcenter'>
                  <div className='col_auto RegisterButton'>
                    {
                      !inProgress
                        ? (
                          <Button
                            type='submit'
                            variant={ButtonVariant.bg}
                            inactive={error === RegisterError.NO_STORE_ADDRESS}
                          >
                            Stwórz konto
                            <img src={FcLogo} alt='FullCall logo' height={18} style={{ marginLeft: 2, marginRight: 1 }} /> FullCall
                          </Button>
                          )
                        : (
                          <div className='box-loader RegisterLoadingText'>
                            Tworzenie...
                          </div>
                          )
                    }

                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Register;
