import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className='PrivacyPolicySection'>
      <a
        className='PrivacyPolicyLink'
        href='https://fullcall.com/regulamin/'
        target='_blank'
        rel='noreferrer'
      >
        Polityka prywatności
      </a>
    </div>
  );
};

export default PrivacyPolicy;
