import React from 'react';
import TickListText from './common/TickListText';
import './Benefits.css';

const Benefits = () => {
  return (
    <>
      <h2>Co zyskujesz z FullCall?</h2>
      <ul className='Benefits'>
        <TickListText>Większe <b>zaufanie do oferty</b> oraz bezpośrednią<b> możliwość zwiększenia sprzedaży</b></TickListText>
        <TickListText><b>Pozytywne opinie</b> oraz <b>zadowolonych, chętniej polecających Twój biznes klientów</b></TickListText>
        <TickListText>Lepsze pierwsze wrażenie, <b>wzrost zaufania do marki</b> oraz jej autentyczności</TickListText>
        <TickListText>Diametralny <b>spadek reklamacji</b> oraz rozczarowanych klientów</TickListText>
        <TickListText><b>Wyróżnienie się na tle konkurencji</b></TickListText>
      </ul>
    </>
  );
};

export default Benefits;
