import React from 'react';
import './Button.css';

export const ButtonVariant = {
  outline: 'btn_outline',
  bg: 'btn_bg',
  onBg: 'btn_on-bg'
};

export const ButtonSize = {
  s: 'btn_s',
  normal: ''
};

const Button = ({
  children,
  type = 'button',
  variant = ButtonVariant.outline,
  size = ButtonSize.normal,
  inactive = false,
  onClick: handleClick
}) => {
  return (
    <button
      className={`btn ${variant} ${size} ${inactive && 'btn_inactive'} Button`}
      type={type}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export default Button;
