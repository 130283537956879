import React from 'react';
import Banner from './common/Banner';
import { ReactComponent as AppIcon } from 'assets/page/icon_app.svg';
import { ReactComponent as ResolutionIcon } from 'assets/page/icon_resolution.svg';
import { ReactComponent as CursorIcon } from 'assets/page/icon_cursor.svg';
import { ReactComponent as Tick } from 'assets/page/tick.svg';
import './Banners.css';

const Banners = () => {
  return (
    <div className='Banners'>
      <Banner>
        <AppIcon height={104} />
        <figcaption className='Banner-caption'>
          <span className='Banner-caption-tick-row'>
            <Tick className='Banner-caption-tick' />
            Wygodna i intuicyjna
          </span>
          <b>aplikacja mobilna iOS / Android</b>
        </figcaption>
      </Banner>
      <Banner fcBorder>
        <ResolutionIcon height={104} />
        <figcaption className='Banner-caption'>
          <span className='Banner-caption-tick-row'>
            <Tick className='Banner-caption-tick' />
            <b>Zarządzanie rozdzielczością</b>
          </span>
          <span>podczas rozmowy</span>
        </figcaption>
      </Banner>
      <Banner>
        <CursorIcon height={104} />
        <figcaption className='Banner-caption'>
          <span className='Banner-caption-tick-row'>
            <Tick className='Banner-caption-tick' />
            Możliwość udostępnienia
          </span>
          <b>ekranu oraz kursora</b>
        </figcaption>
      </Banner>
    </div>
  );
};

export default Banners;
