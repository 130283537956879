import React from 'react';
import { ReactComponent as FbLogo } from 'assets/page/facebook.svg';
import { ReactComponent as InLogo } from 'assets/page/instagram.svg';
import { ReactComponent as LiLogo } from 'assets/page/linkedin.svg';
import './Social.css';

const Social = () => {
  return (
    <div className='Social'>
      <a href='https://www.facebook.com/FullCallCom' target='_blank' rel='noreferrer'><FbLogo /></a>
      <a href='https://www.instagram.com/fullcall.pl/' target='_blank' rel='noreferrer'><InLogo /></a>
      <a href='https://www.linkedin.com/company/fullcall/' target='_blank' rel='noreferrer'><LiLogo /></a>
    </div>
  );
};

export default Social;
