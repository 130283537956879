import React from 'react';

export const MessageBoxVariant = {
  alert: 'message-box_alert',
  warning: 'message-box_warning',
  success: 'message-box_success',
  light: 'message-box_light'
};

const MessageBox = ({
  title = '',
  content = '',
  variant = MessageBoxVariant.light
}) => {
  return (
    <div className={`message-box ${variant}`}>
      <div className='message-box__icon' />

      <div className='message-box__content'>
        <h4 className='message-box__header'>{title}</h4>

        <p>
          {content}
        </p>
      </div>
    </div>
  );
};

export default MessageBox;
