import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import initShoperApp from 'initShoperApp';
import Footer from './Footer/components/Footer';
import Header from './Header/components/Header';
import MainContent from './MainContent/components/MainContent';

const Welcome = () => {
  const history = useHistory();
  const handleRegister = useCallback(() => {
    history.push('/register');
  }, [history]);

  const handleLogin = useCallback(() => {
    history.push('/login');
  }, [history]);

  useEffect(() => {
    const token = window.localStorage.getItem('fcToken');
    initShoperApp();
    if (token) {
      history.replace('/open-panel');
    }
  }, [history]);

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#fafafa',
      flexDirection: 'column'
    }}
    >
      <Header onRegister={handleRegister} onLogin={handleLogin} />
      <MainContent onRegister={handleRegister} />
      <Footer />
    </div>
  );
};

export default Welcome;
