
const init = () => {
  try {
    if (!window.shop) {
      const shop = new window.ShopApp(
        (app) => {
          app.init(null, (_, app) => {
            app.show(null, () => app.adjustIframeSize());
          });
        },
        (error, _) => {
          console.debug(error);
        }
      );
      window.shop = shop;
    }
    return window.shop;
  } catch (e) {
    return null;
  }
};

export default init;
