import React from 'react';
import ScreenShot from 'assets/page/ss.jpg';
import Banners from './Banners/components/Banners';
import Benefits from './Banners/components/Benefits';
import FcButton from 'components/common/fc/FcButton';
import './MainContent.css';

const MainContent = ({ onRegister: handleRegister }) => {
  return (
    <main className='MainContent'>
      <img
        className='ScreenShot'
        src={ScreenShot}
        alt='FullCall videochat screenshot'
      />
      <Banners />
      <Benefits />
      <div className='Buttons'>
        <FcButton onClick={handleRegister}>
          Załóż <b>bezpłatne konto</b>
        </FcButton>
      </div>
    </main>
  );
};

export default MainContent;
