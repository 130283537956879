import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const OpenPanelHeader = () => {
  const history = useHistory();

  const handleLogout = useCallback(() => {
    window.localStorage.removeItem('fcToken');
    history.replace('/');
  }, [history]);

  return (
    <header className='ui__section-header'>
      <div className='row'>
        <div className='col_auto'>
          <button
            className='link size_s'
            onClick={handleLogout}
          >
            <span className='icon icon-arrow mr-1 icon_rotate-180 size_xs line-height_m' /><span className='align_middle'>wróć do: Logowanie</span>
          </button>
        </div>
      </div>

      <div className='ui__section-title-actions'>
        <div className='col_grow'>
          <h2 className='h3'>
            Otwórz panel FullCall
          </h2>
        </div>
      </div>
    </header>
  );
};

export default OpenPanelHeader;
