import React, { useCallback } from 'react';
import FcLogoColor from 'assets/sygnet_color.svg';
import { useHistory } from 'react-router-dom';

const RegisterHeader = () => {
  const location = document.referrer.replace(/http(s):\/\//, '').replace(/\/$/, '');
  const history = useHistory();

  const handleLogin = useCallback(() => {
    history.replace('/login');
  }, [history]);

  return (
    <header className='ui__section-header'>
      <div className='ui__section-title-actions'>
        <div className='col_grow'>
          <h2 className='h3'>
            Stwórz darmowe konto dla {location}
          </h2>
        </div>

        <aside className='col_auto'>
          <div className='row row_vcenter'>
            <div className='col_auto hidden-xs-down'>
              <div className='buttons'>
                <button
                  className='btn btn_outline btn_s'
                  onClick={handleLogin}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Mam już konto
                  <img src={FcLogoColor} alt='FullCall logo' height={16} style={{ marginLeft: 2 }} /> FullCall
                </button>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </header>
  );
};

export default RegisterHeader;
