import React from 'react';
import './Banner.css';

const Banner = ({ children, fcBorder = false }) => {
  return (
    <figure className={`Banner ${fcBorder ? 'Banner-fcBorder' : 'Banner-greyBorder'}`}>
      {children}
    </figure>
  );
};

export default Banner;
