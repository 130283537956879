import React, { useCallback, useMemo } from 'react';

const Checkbox = ({
  checked = false,
  label = '',
  onChange
}) => {
  const handleChange = useCallback((e) => {
    if (onChange) {
      onChange(e.target.checked);
    }
  }, [onChange]);

  const id = useMemo(() => `checkbox-label-${label}`, [label]);

  return (
    <div className='controls'>
      <div className='controls__content'>
        <div className='controls__element controls__element_checkbox'>
          <span className='checkbox'>
            <input
              id={id}
              className='checkbox__control'
              type='checkbox'
              checked={checked}
              onChange={handleChange}
            />
            <label className='checkbox__label' htmlFor={id} />
          </span>

          <label className='label' htmlFor={id}>
            {label}
          </label>
        </div>
      </div>
    </div>

  );
};

export default Checkbox;
