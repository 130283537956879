import React from 'react';
import { ReactComponent as FcLogo } from 'assets/page/footer_logo.svg';
import Social from './Social/components/Social';
import PrivacyPolicy from './PrivacyPolicy/components/PrivacyPolicy';
import Copyright from './Copyright/components/Copyright';

const Footer = () => {
  return (
    <footer>
      <FcLogo />
      <Social />
      <PrivacyPolicy />
      <Copyright />
    </footer>
  );
};

export default Footer;
