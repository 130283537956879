import React from 'react';
import { ReactComponent as HeaderImage } from 'assets/page/header.svg';
import FcButton from 'components/common/fc/FcButton';
import FcButtonOutline from 'components/common/fc/FcButtonOutline';

const Header = ({ onRegister: handleRegister, onLogin: handleLogin }) => {
  return (
    <header style={{ maxWidth: 1280 }}>
      <HeaderImage style={{ width: '100%', height: 150 }} />
      <div
        className='box_spacing'
        style={{
          width: '100%',
          textAlign: 'center',
          background: 'white',
          marginTop: -64,
          paddingBottom: 80,
          paddingTop: 80,
          borderRadius: 8
        }}
      >
        <h1 style={{ fontWeight: 'normal' }}>
          <b>FullCall</b> – wideoczat FullHD dla Twojej witryny!
        </h1>
        <p style={{ fontSize: 18, lineHeight: '28px' }}>
          Wtyczka dedykowana dla sklepów internetowych. <br /> Umożliwia prowadzenie rozmów czat, wideo oraz audio <br /> bezpośrednio na stronie internetowej sklepu.
        </p>
        <FcButton onClick={handleRegister}>
          Załóż <b>bezpłatne konto</b>
        </FcButton> {' '}
        <FcButtonOutline onClick={handleLogin}>
          Mam już konto
        </FcButtonOutline>
      </div>
    </header>
  );
};

export default Header;
