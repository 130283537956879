import Register from 'components/Register/components/Register';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Login from './components/Login/components/Login';
import OpenPanel from './components/OpenPanel/components/OpenPanel';
import Welcome from 'components/Welcome/components/Welcome';

function App () {
  return (
    <div className='App'>
      <HashRouter>
        <Switch>
          <Route exact path='/' component={Welcome} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/register' component={Register} />
          <Route exact path='/open-panel' component={OpenPanel} />
          <Route path='*' component={Login} />
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
