import React from 'react';
import { ReactComponent as Tick } from 'assets/page/tick.svg';
import './TickListText.css';

const TickListText = ({ children }) => {
  return (
    <li className='TickListText'><Tick className='ListTick' />{children}</li>
  );
};

export default TickListText;
