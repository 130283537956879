import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button, { ButtonSize, ButtonVariant } from 'components/common/Button';
import TextField from 'components/common/TextField';
import MessageBox, { MessageBoxVariant } from 'components/common/MessageBox';
import LoginHeader from './LoginHeader';
import FcLogo from 'assets/sygnet_color.svg';
import { FC_HTTP_ROOT } from 'config';
import './Login.css';
import { openFcDashboard } from 'fc';

const LoginError = {
  EMPTY_FIELDS: 'EMPTY_FIELDS',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS'
};

const Login = () => {
  const [error, setError] = useState(false);
  const [inProgress, setProgress] = useState(false);
  const history = useHistory();

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();

    if (inProgress) {
      return;
    }
    const [email, password] = [...event.target.querySelectorAll('input')].map(({ value }) => value);
    if (!email || !password) {
      setError(LoginError.EMPTY_FIELDS);
      return;
    }

    setProgress(true);
    const loginRequestResult = await window.fetch(`${FC_HTTP_ROOT}/api/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password })
    });
    setProgress(false);
    if (loginRequestResult.status >= 400) {
      setError(LoginError.INVALID_CREDENTIALS);
      return;
    }

    const { token } = await loginRequestResult.json();
    window.localStorage.setItem('fcToken', token);
    openFcDashboard(token);
    history.replace('/open-panel');
  }, [
    inProgress,
    setError,
    setProgress,
    history
  ]);

  return (
    <div className='row'>
      <div className='col_xs-12'>
        <LoginHeader />
        <div className='ui__section-main'>
          <section className='col_xs-12 ui__section-content'>
            <div className='ui__section-content-box LoginContent'>
              <form onSubmit={handleSubmit}>
                <span className='ui__form-legend ui__form-legend_sticky'>
                  Wprowadź dane logowania
                  <img src={FcLogo} alt='FullCall logo' height={26} style={{ marginLeft: 4 }} />
                  FullCall
                </span>

                {
                  error === LoginError.EMPTY_FIELDS && (
                    <div className='row row_hcenter'>
                      <div className='col_auto'>
                        <MessageBox
                          title='Wprowadź dane logowania'
                          content='Pola nie mogą być puste'
                          variant={MessageBoxVariant.warning}
                        />
                      </div>
                    </div>
                  )
                }

                {
                  error === LoginError.INVALID_CREDENTIALS && (
                    <div className='row row_hcenter'>
                      <div className='col_auto'>
                        <MessageBox
                          title='Nieudane logowanie'
                          content='Ponownie sprawdź wprowadzone dane logowania.'
                          variant={MessageBoxVariant.alert}
                        />
                      </div>
                    </div>
                  )
                }

                <div className='row row_hcenter'>
                  <div className='col_auto'>
                    <TextField
                      label='Email'
                      fieldName='email'
                      type='email'
                      placeholder='email@example.com'
                    />
                  </div>
                </div>

                <div className='row row_hcenter'>
                  <div className='col_auto'>
                    <TextField
                      label='Hasło'
                      fieldName='password'
                      type='password'
                      placeholder=''
                    />
                  </div>
                </div>

                <div className='row row_hcenter'>
                  <div className='col_auto LoginButton'>
                    {
                      !inProgress
                        ? (
                          <Button
                            type='submit'
                            variant={ButtonVariant.bg}
                            size={ButtonSize.s}
                          >
                            Zaloguj
                          </Button>
                          )
                        : (
                          <div className='box-loader LoginLoadingText'>
                            Logowanie...
                          </div>
                          )
                    }

                  </div>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Login;
