import React from 'react';
import './FcButtonOutline.css';

const FcButtonOutline = ({
  children,
  type = 'button',
  onClick: handleClick
}) => {
  return (
    <button
      className='FcButtonOutline'
      type={type}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export default FcButtonOutline;
