import React, { useCallback, useEffect, useMemo } from 'react';
import Button, { ButtonVariant } from 'components/common/Button';
import OpenPanelHeader from './OpenPanelHeader';
import FcLogo from 'assets/sygnet_white.svg';
import FcLogoColor from 'assets/sygnet_color.svg';
import { useHistory } from 'react-router-dom';
import { openFcDashboard } from 'fc';

const OpenPanel = () => {
  const token = useMemo(() => window.localStorage.getItem('fcToken'), []);

  const handleOpenPanel = useCallback(() => {
    openFcDashboard(token);
  }, [token]);

  const history = useHistory();
  useEffect(() => {
    if (!token) {
      history.replace('/');
    }
  }, [token, history]);

  return (
    <div className='row'>
      <div className='col_xs-12'>
        <OpenPanelHeader />

        <div className='ui__section-main'>
          <section className='col_xs-12 ui__section-content'>
            <div className='ui__section-content-box' style={{ paddingBottom: '1em' }}>
              <span className='ui__form-legend ui__form-legend_sticky'>
                Uruchom panel
                <img src={FcLogoColor} alt='FullCall logo' height={26} style={{ marginLeft: 4 }} /> FullCall
                w nowym oknie
              </span>

              <div className='row row_hcenter'>
                <div className='col_xs-12 col_md-5 col_lg-4' style={{ display: 'flex', flexDirection: 'column' }}>
                  <p>Aby odpowiadać klientom na czacie i odbierać przychodzące połączenia przejdź do panelu FullCall.</p>
                  <Button
                    onClick={handleOpenPanel}
                    variant={ButtonVariant.bg}
                  >
                    <img src={FcLogo} width='24' style={{ marginRight: 6 }} alt='FullCall logo' />
                    Otwórz panel FullCall
                  </Button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default OpenPanel;
